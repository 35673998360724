import * as Sentry from '@sentry/browser'

interface ErrorMessageObject {
	name: string
	message: string
}

export const log = (...params: unknown[]) => {
	window.__DEV__ && console.log(...params)
}
export const warn = (...params: unknown[]) => {
	window.__DEV__ && console.warn(...params)
}

export const logError = (errorMessageObject: ErrorMessageObject) => {
	const error = new Error(
		`${errorMessageObject.name} | ${errorMessageObject.message}`,
	)

	if (window.__DEV__) {
		console.error(error)
	} else {
		Sentry.captureException(error)
	}
}
