import EventEmitter from 'events'
import TypedEmitter from 'typed-emitter'

type LoadingOverlayEvents = {
	'loading:start': (message?: string) => void
	'loading:stop': () => void
}

type ImagePreviewEvents = {
	'imagePreview:change': (files: FileList | null) => void
}

type EssenszuschussUploadEvents = {
	'essenszuschussUpload:file-change': (files: FileList | null) => void
}

type EssenszuschussUploadOverlayEvents = {
	'essenszuschussUploadOverlay:form-loaded': (responseText: string) => void
}

export type EmitterEvents = LoadingOverlayEvents &
	ImagePreviewEvents &
	EssenszuschussUploadEvents &
	EssenszuschussUploadOverlayEvents

export const emitter = new EventEmitter() as TypedEmitter<EmitterEvents>
