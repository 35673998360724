import {
	// clear,
	del,
	delMany,
	entries,
	get,
	set,
	update,
	createStore,
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
} from 'idb-keyval'
import * as logging from '~scripts/helper/logging/logging'
import {
	ReceiptDeclinedState,
	ReceiptServerStates,
	ReceiptNotDeclinedStates,
} from '../../../templates/components/Receipt/Receipt.shared'
import type { EssenszuschussBelegstatusResponse } from '../../../templates/pages/essenszuschuss/EssenszuschussBelegstatus/EssenszuschussBelegstatus.shared'

type ReceiptStoreEntryId = string
export type ReceiptStoreEntry = {
	id: ReceiptStoreEntryId
	state:
		| (typeof ReceiptDeclinedState)[number]
		| (typeof ReceiptNotDeclinedStates)[number]
	// ISO 8601
	dateTime: string
	files: {
		file: ArrayBuffer | null
		name: string
		type: string
	}[]
}

const receiptStore = createStore('hrmonyDb', 'receipts')

export const isReceiptStoreEntryValid = (entry: ReceiptStoreEntry) => {
	// console.log('isReceiptStoreEntryValid', entry)
	if (
		entry.id?.length &&
		entry.state?.length &&
		entry.dateTime?.length &&
		entry.files?.length
	) {
		// If a file is `null`
		// the entry is defect
		if (entry.files.find((file) => !file.file)) {
			return false
		}
		return true
	}
	return false
}

export const getAllEntries = async () => {
	// console.log('getAllEntries')
	return await entries<ReceiptStoreEntryId, ReceiptStoreEntry>(receiptStore)
}

export const addEntry = async (entry: ReceiptStoreEntry) => {
	// console.log('addEntry', entry)
	await set(entry.id, entry, receiptStore)
}

export const getEntry = async (id: ReceiptStoreEntryId) => {
	// console.log('getEntry', id)
	const entry = await get<ReceiptStoreEntry>(id, receiptStore)

	return entry
}

export const updateEntry = async (
	id: ReceiptStoreEntryId,
	updates: Partial<ReceiptStoreEntry>,
) => {
	// console.log('updateEntry', id)
	await update(
		id,
		(oldEntry) => {
			// console.log({ title: 'updateEntry', oldEntry, updates })

			return { ...oldEntry, ...updates }
		},
		receiptStore,
	)
}

export const deleteEntry = async (id: ReceiptStoreEntryId) => {
	// console.log('deleteEntry', id)
	await del(id, receiptStore)
}

export const deleteEntries = async (ids: Array<ReceiptStoreEntryId>) => {
	// console.log('deleteEntries', ids)
	await delMany(ids, receiptStore)
}

// TODO add outdated state and check,
// if receipt is from last month after the first couple of days
export const updateAndCleanUpDb = async () => {
	// console.log('Cleanup Db')
	logging.warn('validate items and update state accordingly')

	const entries = await getAllEntries()
	const entriesToDelete: Array<ReceiptStoreEntryId> = []

	try {
		for (const [id, entry] of entries) {
			let response = null

			if (!isReceiptStoreEntryValid(entry)) {
				await updateEntry(id, {
					state: 'defekt',
				})

				continue
			}

			if (
				// @ts-expect-error type error
				[...ReceiptDeclinedState, ...ReceiptServerStates].includes(entry.state)
			) {
				entriesToDelete.push(id)

				continue
			}

			try {
				response = await fetch(`/essenszuschuss/beleg/${id}/status`)
			} catch (error) {
				logging.log({
					name: 'Update Receipt failed',
					message: (error as Error)?.message ?? '',
				})
			}

			try {
				if (response && response.ok) {
					// console.log({ response })

					const decodeHelperElement = document.createElement('div')
					decodeHelperElement.innerHTML = await response.text()
					const belegStatusResponse = JSON.parse(
						decodeHelperElement.innerText,
					) as EssenszuschussBelegstatusResponse

					const state = belegStatusResponse.items.find(
						(item) => item.belegId === id,
					)?.status

					if (state !== undefined && entry.state !== state) {
						await updateEntry(id, { state })
					}
				}
			} catch (error) {
				logging.logError({
					name: 'Update Receipt failed',
					message: (error as Error)?.message ?? '',
				})
			}
		}

		if (entriesToDelete.length > 0) {
			await deleteEntries(entriesToDelete)
		}
	} catch (error) {
		logging.logError({
			name: 'Cleanup Db failed',
			message: (error as Error)?.message ?? '',
		})
	}
}

// addEntry({
// 	id: uuidv4(),
// 	mitarbeiterId: uuidv4(),
// 	date: new Date(Date.now() - UPLOAD_MAX_TIME),
// 	state: 'uploading',
// 	retries: 0,
// 	file: [] as Receipt[],
// })
// 	.catch(error => { logging.warn(error) })
