import {
	registerComponent,
	TComponentInit,
	findAndInitAllComponents,
} from '@hrmony/component-library/scripts'
import type { EssenszuschussUploadOverlayConfig } from './EssenszuschussUploadOverlay.shared'
import dialogPolyfill from 'dialog-polyfill'
import { emitter, logging } from '../../../scripts'

export const identifier = 'essenszuschuss-upload-overlay'

const EssenszuschussUploadOverlayComponent: TComponentInit = (element) => {
	const config: EssenszuschussUploadOverlayConfig = JSON.parse(
		element.getAttribute('data-component-config')!,
	)
	const wrapper = element.querySelector<HTMLElement>(
		'[data-upload-input-wrapper]',
	)
	const fileInput = element.querySelector<HTMLInputElement>('[type="file"]')
	const dialog = element.querySelector<HTMLDialogElement>(
		'[data-essenszuschuss-upload-overlay-dialog]',
	)
	let closeTrigger: Element | null = null
	let isUploadFormLoaded = false

	if (!dialog) {
		return {
			element,
			init: () => {},
			destroy: () => {},
		}
	}

	dialogPolyfill.registerDialog(dialog)

	const onChange = () => {
		// console.log('onChange')
		if (!isUploadFormLoaded) {
			// eslint-disable-next-line @typescript-eslint/no-use-before-define
			return loadUploadOverlay()
		}

		if (isUploadFormLoaded) {
			emitter.emit('essenszuschussUpload:file-change', fileInput?.files ?? null)
			dialog.showModal()

			// reset input to fix issue where the
			// change event is not triggered a second time
			// if the user selects the same file
			if (fileInput) {
				fileInput.value = ''
			}
		}
	}

	const destroyUploadOverlay = () => {
		// console.log('destroyUploadOverlay')
		dialog.innerHTML = ''
		isUploadFormLoaded = false
	}

	const onClose = (event?: Event) => {
		event?.preventDefault()

		dialog.close()
		destroyUploadOverlay()
	}

	const initUploadOverlay = (responseText: string) => {
		dialog.innerHTML = responseText

		findAndInitAllComponents(dialog)

		closeTrigger = dialog.querySelector('[data-overlay-close]')

		closeTrigger?.addEventListener('click', onClose)
	}

	const updateUploadOverlay = (responseText: string) => {
		destroyUploadOverlay()
		initUploadOverlay(responseText)
		dialog.showModal()
	}

	const loadUploadOverlay = async () => {
		if (isUploadFormLoaded) {
			return
		}

		try {
			const response = await fetch(`${config.action}`, {
				headers: {
					'HX-Request': 'true',
				},
			})

			const body = await response.text()
			initUploadOverlay(body)

			isUploadFormLoaded = true

			if (fileInput?.files && fileInput?.files.length) {
				onChange()
			}
		} catch (error) {
			// TODO handle load error
			logging.logError(error as Error)
		}
	}

	const init = () => {
		loadUploadOverlay()

		emitter.on('essenszuschussUploadOverlay:form-loaded', updateUploadOverlay)
		fileInput?.addEventListener('change', onChange)

		wrapper?.removeAttribute('hidden')
	}

	function destroy() {
		destroyUploadOverlay()

		emitter.off('essenszuschussUploadOverlay:form-loaded', updateUploadOverlay)
		fileInput?.removeEventListener('change', onChange)

		wrapper?.setAttribute('hidden', 'true')
	}

	return {
		element,
		init,
		destroy,
	}
}

registerComponent(identifier, EssenszuschussUploadOverlayComponent)

export default {
	identifier,
	EssenszuschussUploadOverlayComponent,
}
