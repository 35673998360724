import {
	registerComponent,
	TComponentInit,
} from '@hrmony/component-library/scripts'

const identifier = 'copy-and-redirect'

const CopyAndRedirect: TComponentInit = (element) => {
	const code = element.querySelector('[data-code]')
		? element.querySelector('[data-code]')?.getAttribute('data-code')
		: null

	const partnerLink = element.querySelector<HTMLAnchorElement>(
		'[data-partner-link]',
	)
	const noJSPartnerLink = element.querySelector<HTMLAnchorElement>(
		'[data-no-js-partner-link]',
	)
	const hasCodeToCopy =
		partnerLink?.getAttribute('data-partner-link') === 'true'

	const copyToClipboard = () => {
		navigator.clipboard.writeText(code ?? '')
	}

	const onClick = (event: Event) => {
		event.preventDefault()

		if (hasCodeToCopy) {
			copyToClipboard()
		}

		if (partnerLink && partnerLink.target === '_blank') {
			window.open(partnerLink.href, '_blank')
		} else {
			console.error('No link found')
		}
	}

	partnerLink?.addEventListener('click', onClick)

	const init = () => {
		partnerLink?.removeAttribute('hidden')
		noJSPartnerLink?.setAttribute('hidden', 'true')
	}

	const destroy = () => {
		partnerLink?.removeEventListener('click', onClick)
	}

	return {
		element,
		init,
		destroy,
	}
}

registerComponent(identifier, CopyAndRedirect)

export default {
	identifier,
	CopyAndRedirect,
}
