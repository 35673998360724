import {
	registerComponent,
	TComponentInit,
} from '@hrmony/component-library/scripts'

export const identifier = 'snap-slider'

type SliderActiveElement = Element & {
	__setSliderActive: (event: Event) => void
}

let observer: IntersectionObserver

const SnapSlider: TComponentInit = (element) => {
	const slideButtons = element.querySelectorAll(
		'[data-slide-button]',
	) as NodeListOf<SliderActiveElement> | null
	const slidesContainer = element.querySelector('[data-slides-container]')
	const slides = slidesContainer?.querySelectorAll('[data-slide]')

	const getCorrespondingSlide = (slideButton: SliderActiveElement) => {
		const slideButtonIndex = slideButton.getAttribute('data-slide-button')
		const correspondingSlide = element.querySelector(
			`[data-slide="${slideButtonIndex}"]`,
		)

		return correspondingSlide
	}

	const setSlideActive = (slideButton: SliderActiveElement) => {
		if (!slideButtons) {
			return
		}

		const correspondingSlide = getCorrespondingSlide(slideButton)

		if (correspondingSlide) {
			correspondingSlide.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
				inline: 'start',
			})
		}

		slideButtons.forEach((slideButton) => {
			slideButton.setAttribute('aria-current', 'false')
		})

		slideButton.setAttribute('aria-current', 'true')
	}

	slideButtons?.forEach((slideButton) => {
		slideButton.__setSliderActive = setSlideActive.bind(null, slideButton)
		slideButton.addEventListener('click', slideButton.__setSliderActive)
	})

	function init() {
		if (!slideButtons) {
			return
		}
		observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					const slide = entry.target
					const slideIndex = slide.getAttribute('data-slide')
					const correspondingButton = document.querySelector(
						`[data-slide-button="${slideIndex}"]`,
					)

					if (correspondingButton) {
						if (entry.isIntersecting) {
							correspondingButton.setAttribute('aria-current', 'true')
						} else {
							correspondingButton.setAttribute('aria-current', 'false')
						}
					}
				})
			},
			{
				root: slidesContainer,
				threshold: 0.5,
			},
		)

		slides?.forEach((slide) => {
			observer.observe(slide)
		})
	}

	function destroy() {
		slideButtons?.forEach((slideButton) => {
			slideButton.removeEventListener('click', slideButton.__setSliderActive)
		})

		/** Destroying observers
		 * https://www.reddit.com/r/learnreactjs/comments/12wpqqq/is_using_both_observerunobserve_and/
		 */
		slides?.forEach((slide) => {
			observer.disconnect()
			observer.unobserve(slide)
		})
	}

	return {
		element,
		init,
		destroy,
	}
}

registerComponent(identifier, SnapSlider)

export default {
	identifier,
	SnapSlider,
}
