import {
	registerComponent,
	TComponentInit,
	// findAndInitAllComponents,
} from '@hrmony/component-library/scripts'
import { receiptStore } from '~scripts'
import { ReceiptClientStates } from '~templates/components/Receipt/Receipt.shared'

export const identifier = 'tab-navigation'

const TabNavigationComponent: TComponentInit = (element) => {
	const addReceiptStoreCountItems = element.querySelectorAll(
		'[data-add-receipt-store-count]',
	)

	const updateCountItems = async () => {
		let receiptStoreCount = 0

		try {
			receiptStoreCount = (await receiptStore.getAllEntries()).filter(
				([, entry]) => {
					// @ts-expect-error type error
					return ReceiptClientStates.includes(entry.state)
				},
			).length
		} catch {
			receiptStoreCount = 0
		}

		if (receiptStoreCount === 0) {
			return
		}

		addReceiptStoreCountItems.forEach((item) => {
			const count = parseInt(
				item.getAttribute('data-initial-badge-count') || '0',
				10,
			)

			item.textContent = (count + receiptStoreCount).toString()
			item.removeAttribute('hidden')
		})
	}

	const init = () => {
		updateCountItems()
	}

	function destroy() {}

	return {
		element,
		init,
		destroy,
	}
}

registerComponent(identifier, TabNavigationComponent)

export default {
	identifier,
	TabNavigationComponent,
}
