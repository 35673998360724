import {
	registerComponent,
	TComponentInit,
} from '@hrmony/component-library/scripts'

const identifier = 'voucher-select'

const VoucherSelectComponent: TComponentInit = (element) => {
	const select = element.querySelector('select')
	const noteWrapper = element.querySelector('[data-voucher-select-note')
	const renewalNoteWrapper = element.querySelector(
		'[data-voucher-select-renewal-note',
	)

	const onChange = () => {
		const selectedOption = select?.selectedOptions[0]
		const note = selectedOption?.getAttribute('data-note')
		const renewalNote = selectedOption?.getAttribute('data-renewal-note')

		noteWrapper?.setAttribute('hidden', '')
		renewalNoteWrapper?.setAttribute('hidden', '')

		if (noteWrapper && note) {
			noteWrapper.textContent = note
			noteWrapper.removeAttribute('hidden')
		}

		if (renewalNoteWrapper && renewalNote) {
			renewalNoteWrapper.textContent = renewalNote
			renewalNoteWrapper.removeAttribute('hidden')
		}
	}

	const init = () => {
		;[...(select?.options ?? [])].forEach((option) => {
			option.setAttribute('data-value', option.text)
			option.text = option.getAttribute('data-value') ?? option.text
		})

		select?.addEventListener('change', onChange)

		onChange()
	}

	const destroy = () => {
		;[...(select?.options ?? [])].forEach((option) => {
			option.text = option.getAttribute('data-plain-value') ?? option.text
		})

		select?.removeEventListener('change', onChange)
	}

	return {
		element,
		init,
		destroy,
	}
}

registerComponent(identifier, VoucherSelectComponent)

export default {
	identifier,
	VoucherSelectComponent,
}
