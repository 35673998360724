import {
	registerComponent,
	TComponentInit,
} from '@hrmony/component-library/scripts'

export const identifier = 'offline-banner'
const offlineClassName = `${identifier}--offline`
const offlineModeClassName = 'offline-mode-active'

const OfflineBannerComponent: TComponentInit = (element) => {
	function onOnline() {
		document.documentElement.classList.remove(offlineModeClassName)
		element.classList.remove(offlineClassName)
	}

	function onOffline() {
		document.documentElement.classList.add(offlineModeClassName)
		element.classList.add(offlineClassName)
	}

	function init() {
		element.removeAttribute('hidden')
		element.setAttribute('aria-live', 'assertive')

		window.addEventListener('offline', onOffline)
		window.addEventListener('online', onOnline)

		if (!navigator.onLine) {
			onOffline()
		}
	}

	function destroy() {
		window.removeEventListener('offline', onOffline)
		window.removeEventListener('online', onOnline)

		element.removeAttribute('aria-live')
		element.setAttribute('hidden', '')
	}

	return {
		element,
		init,
		destroy,
	}
}

registerComponent(identifier, OfflineBannerComponent)

export default {
	identifier,
	OfflineBannerComponent,
}
