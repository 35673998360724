import {
	registerComponent,
	TComponentInit,
	// findAndInitAllComponents,
} from '@hrmony/component-library/scripts'
import { getFormattedDateTime } from '@hrmony/component-library/shared-helper'
// import type { EssenszuschussUploadFormConfig } from '../../pages/essenszuschuss/EssenszuschussUpload/EssenszuschussUpload.shared'
import { receiptStore } from '../../../scripts'
import { arrayBufferToBlob } from '../../../scripts/helper/file-handling/file-handling'
import type { ReceiptsListConfig } from './ReceiptsList.shared.d'

// import { emitter } from '../../../scripts'

export const identifier = 'receipts-list'

const hasTemplateSupport = 'content' in document.createElement('template')

const ReceiptsListComponent: TComponentInit = (element) => {
	const config: ReceiptsListConfig = JSON.parse(
		element.getAttribute('data-component-config')!,
	)
	const receiptList = element.querySelector('[data-receipts-list]')
	const receiptsListItems = [...element.querySelectorAll('[data-receipt-id]')]
	const uploadedReceiptIds = receiptsListItems.map((item) =>
		item.getAttribute('data-receipt-id'),
	)
	const template = element.querySelector<HTMLTemplateElement>('[data-template]')
	let localReceipts: Awaited<ReturnType<typeof receiptStore.getAllEntries>> = []

	const addLocalReceipts = () => {
		localReceipts.forEach(([id, receipt]) => {
			const imgSrcs: string[] = []
			const listItemElement = (
				template?.content.cloneNode(true) as HTMLElement | undefined
			)?.querySelector<HTMLLIElement>('li')
			const receiptElement = (
				template?.content.cloneNode(true) as HTMLElement | undefined
			)?.querySelector<HTMLElement>('.receipt')
			const receiptImg = receiptElement?.querySelector('img')
			const receiptTilte = receiptElement?.querySelector('[data-receipt-date]')

			if (
				!listItemElement ||
				!receiptElement ||
				!receiptList ||
				!hasTemplateSupport
			) {
				return
			}

			receipt.files.forEach((file) => {
				if (!file.file) {
					return
				}

				const fileBlob = arrayBufferToBlob(file.file, file.type)
				imgSrcs.push(window.URL.createObjectURL(fileBlob))
			})

			receiptElement.setAttribute('data-receipt-id', id)
			receiptElement.setAttribute('data-receipt-state', receipt.state)

			if (receiptImg) {
				receiptImg.src = imgSrcs[0]
			}

			if (receiptTilte) {
				const formattedDateTime = getFormattedDateTime(
					config.locale,
					receipt.dateTime,
				)

				receiptTilte.textContent = config.receiptUploadTimeString
					.replace('{day}', formattedDateTime?.shortWeekDay || '')
					.replace('{date}', formattedDateTime?.date || '')
					.replace('{time}', formattedDateTime?.time || '')
			}

			listItemElement.append(receiptElement)

			receiptList.prepend(listItemElement)

			element.classList.remove(`${identifier}--empty`)
		})
	}

	const init = async () => {
		localReceipts = await receiptStore.getAllEntries()
		// Only use receipts not already rendered on page load
		localReceipts = localReceipts
			.filter(([id]) => !uploadedReceiptIds.includes(id))
			.sort(([, receiptA], [, receiptB]) => {
				const dateA = new Date(receiptA.dateTime)
				const dateB = new Date(receiptB.dateTime)

				return dateA.getTime() - dateB.getTime()
			})

		addLocalReceipts()
		// console.log({ localReceipts })
	}

	function destroy() {}

	return {
		element,
		init,
		destroy,
	}
}

registerComponent(identifier, ReceiptsListComponent)

export default {
	identifier,
	ReceiptsListComponent,
}
