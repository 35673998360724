import {
	registerComponent,
	TComponentInit,
} from '@hrmony/component-library/scripts'
import { emitter } from '../../../../scripts'
import { compressImage } from '../../../../scripts/helper/compress-image/compress-image'
import { logging } from '../../../../scripts'
import type { EssenszuschussUploadConfig } from './EssenszuschussUpload.shared'
import { receiptStore } from '../../../../scripts'
import { blobToArrayBuffer } from '../../../../scripts/helper/file-handling/file-handling'
import { ReceiptStoreEntry } from '../../../../scripts/helper/receipt-store/receipt-store'

export const identifier = 'essenszuschuss-upload'

const EssenszuschussUploadComponent: TComponentInit = (element) => {
	const fileInput = element.querySelector<HTMLInputElement>(
		'[data-essenszuschuss-upload-file-input]',
	)
	const config: EssenszuschussUploadConfig = JSON.parse(
		element.getAttribute('data-component-config')!,
	)
	const form = element.querySelector<HTMLFormElement>('form')
	const defaultErrorAlert = element.querySelector('[data-upload-alert-error]')
	const defaultSuccessAlert = element.querySelector(
		'[data-upload-alert-success]',
	)

	// The form can receive files via events and we can't sync file inputs
	// therefore we need to hold the current file in a variable
	let __fileList: DataTransfer | null | undefined = null

	const onFileChange = (initial?: boolean) => {
		element.classList.toggle(`${identifier}--valid`, !!__fileList?.files.length)
		element.classList.toggle(
			`${identifier}--invalid`,
			!__fileList?.files.length,
		)

		if (!initial) {
			defaultErrorAlert?.setAttribute('hidden', 'true')
			defaultSuccessAlert?.setAttribute('hidden', 'true')
		}

		emitter.emit('imagePreview:change', __fileList?.files ?? null)
	}

	const onChange = () => {
		__fileList = new DataTransfer()
		if (!fileInput || !fileInput.files) {
			return
		}

		for (const file of fileInput.files) {
			__fileList.items.add(file)
		}

		onFileChange()
	}

	const onExternalFileChange = (newFiles: FileList | null) => {
		__fileList = new DataTransfer()
		if (!fileInput || !newFiles) {
			return
		}

		for (const file of newFiles) {
			__fileList.items.add(file)
		}

		onFileChange()
	}

	const onSubmit = async (event: Event) => {
		if (!config.enhance || !form || !__fileList) {
			return
		}

		event.preventDefault()
		emitter.emit('loading:start')

		try {
			const formData = new FormData()
			const sourceFormData = new FormData(form)
			const receiptId = sourceFormData.get('key')?.toString().split('/').at(-1)
			const files: ReceiptStoreEntry['files'] = []

			if (!receiptId) {
				throw new Error('Could not get receipt id')
			}

			;[...sourceFormData.entries()].forEach(([key, value]) => {
				if (key === 'file') {
					return
				}

				formData.set(key, value)
			})

			for (const file of __fileList.files) {
				const formDataMethod = formData.has('file') ? 'append' : 'set'
				let compressedFile: File | undefined

				if (
					file.type.match(/application\/pdf/) === null &&
					file.type.match('image/') === null
				) {
					throw new Error(`File type not supported: ${file.type}`)
				}

				if (file.type.match('image/') !== null) {
					const method =
						file.type.match(/image\/png/) !== null ? 'size' : 'quality'

					try {
						compressedFile = await compressImage(file, 500000, method, 0.8)
					} catch (error) {
						throw new Error(
							`Could not compress file (${(error as Error).message})`,
						)
					}
				}

				const fileBuffer = await blobToArrayBuffer(compressedFile || file)

				fileBuffer &&
					files.push({ type: file.type, name: file.name, file: fileBuffer })
				formData[formDataMethod]('file', compressedFile || file)
			}

			// If the user is offline, save the receipt in the indexedDb
			// and redirect to the target page
			if (!window.navigator.onLine) {
				await receiptStore.addEntry({
					id: receiptId,
					dateTime: new Date().toISOString(),
					state: 'offline',
					files,
				})

				return (window.location.href = config.redirectTarget)
			}

			const response = await fetch(form.action, {
				method: 'POST',
				headers: {
					'HX-Request': 'true',
				},
				body: formData,
				credentials: 'include',
				redirect: 'manual',
			})

			// Successful uploaded
			// Try to save receipt in indexedDb and redirect to "InPruefung"
			if (response.status === 0) {
				await receiptStore.addEntry({
					id: receiptId,
					dateTime: new Date().toISOString(),
					state: 'hochgeladen',
					files,
				})
			}

			// Try to load the error upload page
			// if the upload wasn't successful
			else if (!response.ok) {
				await receiptStore.addEntry({
					id: receiptId,
					dateTime: new Date().toISOString(),
					state: 'fehlgeschlagen',
					files,
				})
			}

			window.location.href = config.redirectTarget
		} catch (error) {
			logging.logError({
				name: 'Upload Fehlgeschlagen!',
				message: (error as Error).message,
			})

			// Try reload the upload page to regenerate presigned url
			try {
				const errorResponse = await fetch(config.errorUrl, {
					method: 'GET',
					headers: {
						'HX-Request': 'true',
					},
				})

				if (errorResponse.ok) {
					emitter.emit(
						'essenszuschussUploadOverlay:form-loaded',
						await errorResponse.text(),
					)
				}

				// otherwise show the default error alert
			} catch {
				defaultErrorAlert?.removeAttribute('hidden')
				defaultSuccessAlert?.setAttribute('hidden', 'true')
			}
		} finally {
			emitter.emit('loading:stop')
		}
	}

	const init = () => {
		emitter.on('essenszuschussUpload:file-change', onExternalFileChange)
		fileInput?.removeAttribute('required')
		fileInput?.addEventListener('change', onChange)
		form?.addEventListener('submit', onSubmit)

		onFileChange(true)
	}

	function destroy() {
		emitter.off('essenszuschussUpload:file-change', onExternalFileChange)
		fileInput?.setAttribute('required', 'required')
		fileInput?.removeEventListener('change', onChange)
		form?.removeEventListener('submit', onSubmit)
	}

	return {
		element,
		init,
		destroy,
	}
}

registerComponent(identifier, EssenszuschussUploadComponent)

export default {
	identifier,
	EssenszuschussUploadComponent,
}
