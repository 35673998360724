export const arrayBufferToBlob = (buffer: ArrayBuffer, type: string) => {
	return new Blob([buffer], { type })
}

export const blobToArrayBuffer = async (blob: Blob) => {
	return await new Promise<ArrayBuffer | null>((resolve, reject) => {
		const reader = new FileReader()

		reader.addEventListener('loadend', () => {
			// result can't be a string
			// since we are using `readAsArrayBuffer`
			resolve(reader.result as ArrayBuffer | null)
		})

		reader.addEventListener('error', reject)
		reader.readAsArrayBuffer(blob)
	})
}
