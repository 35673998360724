export const ReceiptDeclinedState = ['declined'] as const
export const ReceiptClientStates = [
	'wirdHochgeladen',
	'hochgeladen',
	'fehlgeschlagen',
	'offline',
	'defekt',
] as const
export const ReceiptServerStates = ['erfasst', 'open'] as const
export const ReceiptNotDeclinedStates = [
	...ReceiptClientStates,
	...ReceiptServerStates,
] as const
