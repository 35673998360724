import {
	registerComponent,
	TComponentInit,
} from '@hrmony/component-library/scripts'
import { emitter } from '../../../scripts'

export const identifier = 'form'
type FormConfig = {
	loadingMessage?: string
}
const LoadingOverlayComponent: TComponentInit = (element) => {
	const form = element.querySelector('[data-form]')
	const config = JSON.parse(
		element.getAttribute('data-form-config') ?? '{}',
	) as FormConfig

	const onSubmit = () => {
		// check against undefined since empty strings are allowed
		if (config.loadingMessage !== undefined) {
			emitter.emit('loading:start', config.loadingMessage)
		}
	}

	return {
		element,
		init: () => {
			form?.addEventListener('submit', onSubmit)
		},
		destroy: () => {
			form?.removeEventListener('submit', onSubmit)
		},
	}
}

registerComponent(identifier, LoadingOverlayComponent)

export default {
	identifier,
	LoadingOverlayComponent,
}
